@import "@/styles/functions";
@import "@/styles/new-variables";

.movieDetailsDescription{
    margin-bottom: toRem(56);
}

.movieDescription{
     
}

.detailsContainer{
    display: flex;
    justify-content: space-between;
    gap: toRem(20);
    &>*{
        flex: 1 1 50%;
    }
    @media (max-width: 767px) {
        flex-direction: column;
    }

}

.description{
    color: $transparent-white-70;
    font-size: toRem(14);
    line-height: 140%;
    margin-bottom: toRem(16);
    // @include textLines(10)
} 

.showMore{
    border-radius: 0;
}

.movieDetails{

}

.detailsList{

}

.detailsItem{
    display: grid;
    align-items: center;
    grid-template-columns: 163px 1fr;
   

    &:not(:first-child){
        padding-top: toRem(16);
        border-top:1px solid $grey-2;
    }

    &:not(:last-child){
        padding-bottom: toRem(16);
    }

}

.detailsLabel{

}

.detailsValue{
    display: flex;
    align-items: center;
    gap: toRem(8);
    font-size: toRem(16);
    font-weight: 500;
    line-height: 140%; 
}