@import '@/styles/variables';

.appsContent {
  padding: 3rem 0;

  .topPanel {
    margin-bottom: 2rem;
  }

  .topPanelHeading {
    font-size: 1.3rem;
  }

  .contentSection{  
    margin-bottom: 3rem;

    &>*:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .contentSectionTitle {
    margin-bottom: 1rem;
  }

  .linksBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;

    >*:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  .appLink {
    display: inline-flex;
    align-items: center;
    border-radius: 10px;
    padding: 0.5rem;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.4);
    background-color: #090909;
  }

  .androidApkText {
    margin-left: 0.3rem;

    &>span {
      display: block;
      line-height: 1.2;
      font-size: 0.8rem;
    }
  }

  .instructionContainer {
    padding: 1rem;
    border-radius: 10px;
    background-color: #090909;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.4);
  }

  .instructionHeading {
    margin-bottom: 0.8rem;
  }

  .instruction {
    padding-left: 1rem;
    font-weight: 300;

    li:not(:last-child) {
      margin-bottom: 0.2rem;
    }
  }

  .instructionNote {
    font-size: 0.9rem;
    font-weight: 300;
    margin-top: 1rem;
  }

  @media(min-width: 576px) {
    .linksBlock {
      flex-direction: row;

      >*:not(:last-child) {
        margin-bottom: 0;
      }
    }

    .appLink {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  @media(min-width: 1200px) {
    .appLink {
      transition: box-shadow 0.2s;

      &:hover {
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
      }
    }
  }
}
