@import "@/styles/functions";
@import "@/styles/new-variables";

.episodesLoaderButton {
  position: relative;
  width: 100%;
  height: 100%;
}

.episodeCardImageWrapper {
  position: relative;
  width: 100%;
}

.spinnerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 2rem;
}

.episodeCardTitle {
  padding: 0 0.5rem 0.5rem;
  opacity: 0;
  visibility: hidden;

  &::before {
    content: "title";
  }

  @media (min-width: 1200px) {
    padding: 0 1rem 0.5rem;
  }
}
