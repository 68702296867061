@import '@/styles/functions';
@import '@/styles/new-variables';

$gap: toRem(12);

.sectionHeading {
  display: flex;
  align-items: center;
  gap: toRem(8);

  .icon {
    color: $grey-4;
  }
}

.commentsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 toRem(15);
  margin-top: toRem(12);
  margin-bottom: toRem(12);

  @media (min-width: get-breakpoint-width('md')) {
    margin-top: 1rem;
    margin-bottom: toRem(20);
  }
}

.commentsSliderWrapper {
  margin-top: toRem(12);

  @media (min-width: get-breakpoint-width('md')) {
    margin-top: toRem(16);
  }
}

.commentsSlide {
  display: flex;
  margin-right: $gap;
  max-width: 100%;
  height: 100%;

  @media (min-width: get-breakpoint-width('md')) {
    max-width: calc((100% - $gap) / 2);
  }

  @media (min-width: get-breakpoint-width('lg')) {
    margin-right: toRem(18);
    max-width: calc((100% - 2 * $gap) / 3);
  }

  @media (min-width: get-breakpoint-width('xl')) {
    margin-right: toRem(20);
    max-width: calc((100% - 3 * $gap) / 4);
  }
}

.sliderWrapper {
  display: flex;
  align-items: stretch;
}
